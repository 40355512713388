<template>
    <div class="container">
        <div class="fw-bold">Custom Strong Dictionary (space, tab or comma-delimited)</div>
        <textarea v-model="customs" class="form-control" rows="20"></textarea>
        <div class="my-2">
            <button type="button" class="btn btn-primary" @click="commit">Save</button>
        </div>
    </div>
</template>

<script>
    const _ = require('lodash');
    const toastr = require('toastr');
    import 'toastr/build/toastr.css';
    export default {
        data() {
            return {
                customs: null
            };
        },
        mounted() {
            const vm = this;
            vm.customs = localStorage.getItem('customs');
        },
        methods: {
            commit() {
                const vm = this;
                localStorage.setItem('customs', _.join(_.map(_.split(vm.customs, '\n'), x => _.split(x, /\s/)), '\n'));
                toastr.success('Saved!');
                vm.customs = localStorage.getItem('customs');
            }
        }
    }
</script>