<template>
    <div class="container">
        <div class="input-group">
            <input type="search" class="form-control" v-model="searchKeyword" placeholder="Enter English word to search..." v-on:keyup.enter="search" autofocus />
            <button type="button" class="btn btn-primary" @click="search">Search</button>
        </div>
        <div class="fw-bold mt-2">
            References: <small>
                <i>({{refNums.length.toLocaleString()}} found)</i>&nbsp;
                <button v-if="sortedRefNums && sortedRefNums.length" class="btn btn-sm btn-secondary" @click="showRefs=!showRefs">Show/Hide</button>
            </small>
        </div>
        <ol @click="popDefinition" v-if="showRefs">
            <li v-for="(item,index) in sortedRefNums" :key="index" v-html="loadDescr(item)"></li>
        </ol>
        <div class="fw-bold mt-2">
            Related Verses: <small>
                <i>({{relatedvs.length.toLocaleString()}} found)</i>&nbsp;
                <button v-if="sortedRelatedvs && sortedRelatedvs.length" class="btn btn-sm btn-secondary" @click="showRelatedVs=!showRelatedVs">Show/Hide</button>
            </small>
        </div>
        <div ref="divRelatedVs" @click="popDefinition" v-if="showRelatedVs">
            <ol class="ms-4">
                <li v-for="(item,index) in sortedRelatedvs" :key="index" v-html="item.text"></li>
            </ol>
        </div>
        <modal name="mdlDefinition" :adaptive="true" :resizable="false" :reset="true" :clickToClose="false" height="auto" :scrollable="true" width="80%">
            <div class="p-2">
                <definition-vue :id="referenceid" @closed="closeDefinition"></definition-vue>
            </div>
        </modal>
    </div>
</template>
<style>
    sup.detail {
        cursor: pointer;
        color: blue;
    }
</style>
<script>
    import DefinitionVue from './Definition.vue';
    const _ = require('lodash');

    export default {
        components: {
            DefinitionVue
        },
        computed: {
            sortedRelatedvs() { return _.orderBy(this.relatedvs, ['bookId'], ['asc']); },
            sortedRefNums() { return _.orderBy(this.refNums, [], ['asc']); },
            keywordCleaned() {
                return (this.searchKeyword && this.searchKeyword.length)
                    ? _.toLower(this.searchKeyword.replace(/\W+/gi, ''))
                    : null;
            }
        },
        data() {
            return {
                searchKeyword: null,
                relatedvs: [],
                refNums: [],
                referenceid: null,
                hebrews: [],
                greeks: [],
                booknames: ["Genesis", "Exodus", "Leviticus", "Numbers", "Deuteronomy", "Joshua", "Judges", "Ruth", "1 Samuel", "2 Samuel", "1 Kings", "2 Kings", "1 Chronicles", "2 Chronicles", "Ezra", "Nehemiah", "Esther", "Job", "Psalms", "Proverbs", "Ecclesiastes", "Song of Solomon", "Isaiah", "Jeremiah", "Lamentations", "Ezekiel", "Daniel", "Hosea", "Joel", "Amos", "Obadiah", "Jonah", "Micah", "Nahum", "Habakkuk", "Zephaniah", "Haggai", "Zechariah", "Malachi", "Matthew", "Mark", "Luke", "John", "Acts", "Romans", "1 Corinthians", "2 Corinthians", "Galatians", "Ephesians", "Philippians", "Colossians", "1 Thessalonians", "2 Thessalonians", "1 Timothy", "2 Timothy", "Titus", "Philemon", "Hebrews", "James", "1 Peter", "2 Peter", "1 John", "2 John", "3 John", "Jude", "Revelation"],
                showRefs: true,
                showRelatedVs: true,
            };
        },
        async mounted() {
            const vm = this;
            await vm.loadDicts();
        },
        methods: {
            closeDefinition() {
                const vm = this;
                vm.referenceid = null,
                    vm.$modal.hide('mdlDefinition');
            },
            popDefinition(e) {
                if (e.target.matches('.detail')) {
                    const vm = this;
                    vm.referenceid = e.target.dataset["referenceid"];
                    vm.$modal.show('mdlDefinition');
                }
            },
            async loadDicts() {
                const vm = this;
                if (vm.hebrews.length && vm.greeks.length) return;
                vm.hebrews = _.split((await (await fetch(`/res/hebrew.txt`)).text()), '\n');
                vm.greeks = _.split((await (await fetch(`/res/greek.txt`)).text()), '\n');
            },
            loadDescr(id) {
                const vm = this;
                const prefix = id.slice(0, 1).toLowerCase();
                const dictName = prefix === "h" ? "hebrews" : "greeks";
                const refNum = +id.replace(/^./, '');
                const line = vm[dictName].find(x => x.match('^' + refNum.toString() + ''));
                const vals = _.split(line, '\t');
                return `<a href='javascript:void(0);' class='detail' data-referenceid='${id}'>${id}</a> - ${vals[1]}<br />${vals[2]}`;
            },
            async search() {
                const vm = this;
                if (!(vm.keywordCleaned && vm.keywordCleaned.length)) return;
                vm.relatedvs = [];
                vm.refNums = [];
                vm.booknames.forEach(async function (book) {
                    const bookId = (vm.booknames.indexOf(book) + 1).toString().padStart(2, '0');
                    //if (+bookId != 48) return;
                    const prefix = +bookId < 40 ? "H" : "G";
                    const lines = _.split((await (await fetch(`/res/${bookId}.txt`)).text()), '\n');
                    const patt2 = `[^A-z]${vm.keywordCleaned}[^A-z]*\\d+`;
                    //console.log({ patt2 });
                    let matchedLines = lines.filter(line => new RegExp(patt2, "gi").test(' '+line));
                    if (!(matchedLines && matchedLines.length)) return;
                    //console.log({ matchedLines });
                    _.each(matchedLines, line => {
                        const vals = _.split(line, '\t');
                        if (!(vals && vals.length >= 3)) return;
                        vals[2].replace(new RegExp(patt2, "gi"), function (matchedText) {
                            matchedText.replace(new RegExp(`.*${vm.keywordCleaned}`,"gi"),'').replace(/\d+/gi, function (refNum) {
                                if (!(refNum && refNum.length)) return;
                                if (!~vm.refNums.indexOf(`${prefix}${refNum}`)) vm.refNums.push(`${prefix}${refNum}`);
                            });
                        });
                        vm.relatedvs.push({ bookId: +bookId, text: `<div class='d-none'>${bookId}</div><div><a href='/Book/${bookId}/${vals[1].replace(':', '_')}' target='_blank'><b>${book} ${vals[1]}</b></a>: ${vm.addsuprefs(vals[2], bookId)}</div>` });
                    })
                });
            },
            addsuprefs(val, id) {
                const vm = this;
                const prefix = +id < 40 ? "H" : "G";
                let outVal = _.replace(val, /\(?(\d+)\)?/gi, `<sup class='detail' data-referenceid='${prefix}${'$1'}'>${prefix}${'$1'}</sup>`);
                const patt = new RegExp(`[^\\w](${vm.keywordCleaned})[^\\w]`, "gi");
                outVal = outVal.replace(patt, function (matchedText) {
                    return matchedText.replace(vm.keywordCleaned, `<span class="badge bg-primary">${vm.keywordCleaned}</span>`)
                });
                return outVal;
            }
        }
    }
</script>